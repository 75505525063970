<template>
  <div
    class="gradient-gray columns is-justify-content-center footer-class py-2"
    :class="toggled ? 'space' : 'not-space'"
  >
    <!-- CATEGORIES -->
    <div class="column is-2 pl-3 pr-3 mt-4 has-text-left has-text-centered-mobile">
      <span
        v-if="categories"
        class="color-red is-uppercase is-size-5 letter-spacing-1 has-text-weight-bold"
        >Productos</span
      >
      <ul
        v-if="categories"
        class="has-text-white has-text-light mt-3 mb-3 cursor-pointer"
      >
        <li
          @click="filterCategory(item.id)"
          v-for="item in categories"
          :key="item.id"
          class="mt-2 is-capitalized categories-footer"
        >
          {{ lower(item.name) }}
        </li>
      </ul>
    </div>
    <!-- CONTACTS -->
    <div class="column is-3 mt-4 pl-3 pr-3 has-text-left has-text-centered-mobile">
      <span
        class="color-red is-uppercase is-size-5 letter-spacing-1 has-text-weight-bold"
        >Contactos</span
      >
      <ul class="has-text-white has-text-light mt-3 mb-3">
        <li v-for="item in contacts" :key="item.id" class="mt-4">
          <span class="icon-text">
            <span
              class="icon is-size-4 mr-3 is-flex-wrap-wrap-reverse z-index-none"
            >
              <i :class="item.icon"></i>
            </span>
            <span class="">{{ item.text }}</span>
          </span>
        </li>
      </ul>
    </div>
    <!-- SOCIALS -->
    <div class="column is-3 mt-4 pl-3 pr-3 has-text-left has-text-centered-mobile">
      <span
        class="color-red is-uppercase is-size-5 letter-spacing-1 has-text-weight-bold"
        >En la web</span
      >
      <ul class="has-text-white has-text-light mt-3 mb-3">
        <li v-for="item in socials" :key="item.id" class="mt-4">
          <a
            :href="item.url"
            target="_blank"
            class="icon-text icon-footer"
            :rel="item.text"
          >
            <span class="icon-text">
              <span class="icon is-size-4 mr-3">
                <i :class="item.icon"></i>
              </span>
              <span>{{ item.text }}</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script src="./footer.component.ts" />
<style lang="scss" src="./footer.component.scss" />
